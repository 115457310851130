import * as React from "react"
import { graphql, PageProps, HeadFC } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import {
  Container,
  FlexList,
  Box,
  Space,
  BlockLink,
  Heading,
  Blockhead,
  Image,
  VisuallyHidden
} from "../components/ui"
import { BlogPost } from "./blog-post"
import { blogPostThumbnail } from "./blog-post.css"

interface BlogCardProps {
  id: string
  slug: string
  image?: Image
  title?: string
  category?: string
  excerpt?: string
}

function BlogCard({
  slug,
  image,
  title,
  excerpt,
  category,
  ...props
}: BlogCardProps) {
  return (
    <BlockLink {...props} to={`/blog/${slug}`}>
      {image && (
        <>
          <GatsbyImage className={blogPostThumbnail} alt={slug} image={getImage(image.localFile)} />
          <Space size={3} />
        </>
      )}
      <Blockhead>{title}</Blockhead>
    </BlockLink>
  )
}

export interface QueryReturn {
  blog: { posts: BlogPost[] }
}

const BlogIndex: React.FC<PageProps<QueryReturn>> = ({ data: { blog: { posts } } }) => {
  return (
    <Layout title="Blog">
      <Container width="narrow">
        <VisuallyHidden><Heading as="h1">Blog</Heading></VisuallyHidden>
        <Box paddingY={4}>
          <FlexList responsive wrap gap={0} gutter={3} variant="start">
            {posts.map((post) => (
              <Box as="li" key={post.id} padding={3} width="third">
                <BlogCard {...post} />
              </Box>
            ))}
          </FlexList>
        </Box>
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const Head: HeadFC<QueryReturn> = ({ data: { blog }, location }) => (
  <SEO title={"Blog"} description={"Marwa Khalil's Blog"} pathname={location.pathname} />
)

export const query = graphql`
  query {
    blog: allContentfulBlogPost {
      posts: nodes {
        id
        slug
        title
        excerpt
        image {
          id
          localFile {
						childImageSharp {
              gatsbyImageData(
                width: 300
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`